import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Tada from "react-reveal/Tada";
import { FaCogs, FaPython, FaJs, FaDatabase, FaNodeJs, FaReact, FaPhp, FaJava } from 'react-icons/fa'; 
import IconC from 'react-devicon/c/line'  
import IconCpp from 'react-devicon/cplusplus/line'
import { SiMongodb,SiExpress,SiPostgresql } from "react-icons/si";
import { TbBrandCpp ,TbBrandCSharp,TbBrandNextjs} from "react-icons/tb";

export default function TechnicalSkill() {
  return (
    <div>
      <div className="mt-5 d-flex flex-row justify-content-center">
        <Link className="btn btn-primary me-3" to="/technicalSkill">
          Technical Skill
        </Link>
        <Link className="btn btn-primary me-3" to="/personalskill">
          Personal Skill
        </Link>
        {/* <Link className="btn btn-primary " to="/toolkit">
          Toolkit
        </Link> */}
      </div>
      <Tada>
        <h1 className="mt-4">Technical Skills</h1>
      </Tada>
      <div className="mt-5">
        <Container>
          <Row className="g-5">
          <Col md={4}>
              <div style={{ fontSize: "100px", color: "#54faae" }}>
                <TbBrandCpp />
              </div>
              <h2
                style={{
                  color: "#54faae",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                C++
              </h2>
            </Col>
            
    
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#39c4ff" }}>
                <FaPython />
              </div>
              <h2
                style={{
                  color: "#39c4ff",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                Python
              </h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#f1f965" }}>
                <FaJs />
              </div>
              <h2
                style={{
                  color: "#f1f965",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                JavaScript
              </h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#41f2ff" }}>
                <FaReact />
              </div>
              <h2
                style={{
                  color: "#41f2ff",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                React JS
              </h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#fe3e57" }}>
              <TbBrandNextjs />
              </div>
              <h2
                style={{
                  color: "#fe3e57",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                NextJS
              </h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#ff8c2f" }}>
                <FaNodeJs />
              </div>
              <h2
                style={{
                  color: "#ff8c2f",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                NodeJS
              </h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#fe3e57" }}>
                <SiExpress />
              </div>
              <h2
                style={{
                  color: "#fe3e57",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                ExpressJS
              </h2>
            </Col>
            
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#ff0173" }}>
                <SiPostgresql />
              </div>
              <h2
                style={{
                  color: "#ff0173",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                PostgreSQL
              </h2>
            </Col>
            
            
            
            <Col md={4}>
              <div style={{ fontSize: "100px", color: "#54faae" }}>
                <SiMongodb />
              </div>
              <h2
                style={{
                  color: "#54faae",
                  fontSize: "25px",
                  marginTop: "13px",
                  fontWeight: 700,
                }}
              >
                MongoDB
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import Flip from "react-reveal/Flip";

function ExperienceJourney() {
  return (
    <div className="mt-5">
      <div>
        <Link className="btn btn-primary m-1" to="/educationjourney">
          Educational Journey
        </Link>
        <Link className="btn btn-primary m-1" to="/experiencejourney">
          Experience Journey
        </Link>
      </div>
      <div className="mt-5">
        <Flip top cascade>
          <h1>Experience Journey</h1>
        </Flip>
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          contentArrowStyle={{ borderLeft: "7px solid  rgb(78 22 112)" }}
          date="02/24 - 06/24"
          iconStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          icon={<PersonIcon />}
        >
          <h5 className="vertical-timeline-element-title">
          Zestminds Technologies Pvt. Ltd.
          </h5>
          <h6 className="vertical-timeline-element-subtitle mt-2">
            FullStack Developer Intern
          </h6>
          <p>• Developed and maintained full-stack web applications for clients' live projects.</p>
          <p>• Contributed to the development of the Zestminds exam portal, enhancing functionalities for proctored exams alongside interviews.</p>
          <p>• Played a pivotal role in backend development for "PAWWALKER," a service booking application catering to pet owners' needs for their beloved pets, as part of a client's project.</p>
          <p>• Implemented responsive design principles to ensure optimal user experience across various devices.</p>
          <p>• Collaborated with team members to troubleshoot and debug issues, ensuring smooth project delivery.</p>
        </VerticalTimelineElement>
      

        <VerticalTimelineElement
          iconStyle={{ background: "#fbd9ad", color: "rgb(78 22 112)" }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
}

export default ExperienceJourney;

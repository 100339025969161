import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import LightSpeed from 'react-reveal/LightSpeed';
import { FaComments, FaTasks, FaBrain, FaUserFriends, FaPuzzlePiece, FaPalette } from 'react-icons/fa'; // Import Font Awesome icons

export default function PersonalSkill() {
  return (
    <div>
      <div className="mt-5 d-flex flex-row justify-content-center">
        <Link className="btn btn-primary me-3" to="/technicalSkill">Technical Skill</Link>
        <Link className="btn btn-primary me-3" to="/personalskill">Personal Skill</Link>
      </div>
      <LightSpeed left cascade>
        <h1 className="mt-4">Personal Skills</h1>
      </LightSpeed>
      <div className="mt-4">
        <Container>
          <Row className='g-5'>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#fe3e57' }}>
                <FaComments />
              </div>
              <h2 style={{ color: '#fe3e57', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Communication Skills</h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#54faae' }}>
                <FaTasks />
              </div>
              <h2 style={{ color: '#54faae', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Project Management</h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#ff8c2f' }}>
                <FaBrain />
              </div>
              <h2 style={{ color: '#ff8c2f', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Problem Solving</h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#f1f965' }}>
                <FaUserFriends />
              </div>
              <h2 style={{ color: '#f1f965', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Analytical Abilities</h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#ff0173' }}>
                <FaPuzzlePiece />
              </div>
              <h2 style={{ color: '#ff0173', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Organization</h2>
            </Col>
            <Col md={4}>
              <div style={{ fontSize: '100px', color: '#39c4ff' }}>
                <FaPalette />
              </div>
              <h2 style={{ color: '#39c4ff', fontSize: '25px', marginTop: '13px', fontWeight: 700 }}>Creativity</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

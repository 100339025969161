import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import platepal from "../../images/platepal.png";
import expenseTracker from "../../images/expenseTracker.png"
import hd from "../../images/hd.png";
import noteease from "../../images/noteease.png";
import { FaCode } from "react-icons/fa";
import { GrDeploy } from "react-icons/gr";
import rapidChat from "../../images/rapidChat.png";

export default function Projects() {
  return (
    <div>
      <Container fluid className="certificate-section" id="about">
        <Container>
          <Row>
            <Col
              md={12}
              className="certificate-description d-flex justify-content-start"
            >
              <Zoom left cascade>
                <h1 className="aboutme-heading">Projects</h1>
              </Zoom>
            </Col>
            <Col md={3}>
              <Fade bottom>
                <div
                  key={1}
                  className="singleProject"
                  style={{
                    backgroundColor: "rgb(142 70 186 / 31%)",
                    border: "1px solid",
                  }}
                >
                  <div className="projectContent">
                    <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                      PlatePal
                    </h5>
                    <img src={platepal} alt={platepal} />
                    < div className="deploy">
                      <div className="">
                        <a
                          href={
                            "https://github.com/Tejvir007/PlatePal"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className={"iconBtn"}
                          aria-labelledby={`code`}
                        >
                          <FaCode
                            id={`code`}
                            className={"icon"}
                            aria-label="Code"
                          />
                        </a>
                      </div>
                      <div className="project--showcaseBtn">
                        <a
                          href={
                            "https://platepal-5oxd.onrender.com/"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className={"iconBtn"}
                          aria-labelledby={`code`}
                        >
                          <GrDeploy
                            id={`code`}
                            className={"icon"}
                            aria-label="Code"
                          />
                        </a>
                      </div>
                      
                    </div>

                  </div>
                  <h6>
                    <p
                      className="project--desc"
                      style={{
                        background: "#fbd9ad",
                        color: "#b061df",
                        fontWeight: 600,
                      }}
                    >
                      A feature-rich Food Delivery Website, using the MERN stack.This involved implementing robust back-end functionalities with Node.js, Express.js, and MongoDB,
                      while also crafting dynamic front-end interfaces with React.js, resulting in a seamless platform
                      for online food ordering with enhanced user experience.

                    </p>
                  </h6>
                  <div
                    className="project--lang"
                    style={{
                      background: "#fbd9ad",
                      color: "#b061df",
                      fontWeight: 600,
                    }}
                  >
                    React.js, Node.js, Express.js, MongoDB
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={3}>
              <Fade bottom>
                <div
                  key={1}
                  className="singleProject"
                  style={{
                    backgroundColor: "rgb(142 70 186 / 31%)",
                    border: "1px solid",
                  }}
                >
                  <div className="projectContent">
                    <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                      RapidChat
                    </h5>
                    <img src={rapidChat} alt={rapidChat} />
                    <div className="deploy">
                    <div className="">
                      <a
                        href={
                          "https://github.com/AnkitBhalla19/RapidChat"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={"iconBtn"}
                        aria-labelledby={`code`}
                      >
                        <FaCode
                          id={`code`}
                          className={"icon"}
                          aria-label="Code"
                        />
                      </a>
                    </div>
                    <div className="project--showcaseBtn">
                        <a
                          href={
                            "https://www.rapidchat.tech/"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className={"iconBtn"}
                          aria-labelledby={`code`}
                        >
                          <GrDeploy
                            id={`code`}
                            className={"icon"}
                            aria-label="Code"
                          />
                        </a>
                      </div>
                      </div>
                  </div>
                  <h6>
                    <p
                      className="project--desc"
                      style={{
                        background: "#fbd9ad",
                        color: "#b061df",
                        fontWeight: 600,
                      }}
                    >
                      Developed a real-time chat application, RapidChat, using
                      Next.js, MongoDB, Socket.io, and Firebase, facilitating seamless communication among users.Utilized Socket.io to enable instant messaging capabilities, allowing users to engage in live
                      conversations with minimal latency, enhancing the overall user experience of RapidChat.
                    </p>
                  </h6>
                  <div
                    className="project--lang"
                    style={{
                      background: "#fbd9ad",
                      color: "#b061df",
                      fontWeight: 600,
                    }}
                  >
                    Next.js, MongoDB, Socket.io, Firebase
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={3}>
              <Fade bottom>
                <div
                  key={1}
                  className="singleProject"
                  style={{
                    backgroundColor: "rgb(142 70 186 / 31%)",
                    border: "1px solid",
                  }}
                >
                  <div className="projectContent">
                    <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                      NoteEase
                    </h5>
                    <img src={noteease} alt={noteease} />
                    <div className="project--showcaseBtn">
                      <a
                        href={
                          "https://github.com/AnkitBhalla19/NoteEase"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={"iconBtn"}
                        aria-labelledby={`code`}
                      >
                        <FaCode
                          id={`code`}
                          className={"icon"}
                          aria-label="Code"
                        />
                      </a>
                    </div>
                  </div>
                  <h6>
                    <p
                      className="project--desc"
                      style={{
                        background: "#fbd9ad",
                        color: "#b061df",
                        fontWeight: 600,
                      }}
                    >
                      NoteEase is a stylish and user-friendly website built using React, designed to facilitate notetaking and organization.
                      •Its primary function is to allow users to create, manage, and delete notes in an efficient and
                      intuitive manner.

                    </p>
                  </h6>
                  <div
                    className="project--lang"
                    style={{
                      background: "#fbd9ad",
                      color: "#b061df",
                      fontWeight: 600,
                    }}
                  >
                    HTML, CSS, React, JavaScript
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={3}>
              <Fade bottom>
                <div
                  key={1}
                  className="singleProject"
                  style={{
                    backgroundColor: "rgb(142 70 186 / 31%)",
                    border: "1px solid",
                  }}
                >
                  <div className="projectContent">
                    <h5 id={"first"} style={{ color: "#fbd9ad" }}>
                      Expense Tracker
                    </h5>
                    <img src={expenseTracker} alt={expenseTracker} />
                    <div className="project--showcaseBtn">
                      <a
                        href={
                          "https://github.com/AnkitBhalla19/expenseTracker"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={"iconBtn"}
                        aria-labelledby={`code`}
                      >
                        <FaCode
                          id={`code`}
                          className={"icon"}
                          aria-label="Code"
                        />
                      </a>
                    </div>
                  </div>
                  <h6>
                    <p
                      className="project--desc"
                      style={{
                        background: "#fbd9ad",
                        color: "#b061df",
                        fontWeight: 600,
                      }}
                    >
                      The Expense Tracker project emerged from a hackathon. This involved integrating graphical representations into the platform to enhance financial management. My contribution spanned both backend and frontend development, ensuring a comprehensive and functional solution for users.
                    </p>
                  </h6>
                  <div
                    className="project--lang"
                    style={{
                      background: "#fbd9ad",
                      color: "#b061df",
                      fontWeight: 600,
                    }}
                  >
                    MongoDB, Express.js, React.js, Node.js
                  </div>
                </div>
              </Fade>
            </Col>

          </Row>
          {/* <div className="blog--viewAll">
            <Link to="/projectspage">
              <button className="btn btn-primary">
                View All
                <HiArrowRight className="viewArr" />
              </button>
            </Link>
          </div> */}
        </Container>
      </Container>
    </div>
  );
}
